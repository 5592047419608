module.exports = {
  siteTitle: 'David Uriel - Personal Links', // <title>
  manifestName: `David Uriel's Personal Links`,
  manifestShortName: 'David Uriel', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'David Uriel',
  heading: 'a web developer ◇ an awesome guitarist',

  // social
  socialLinks: [
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/dave.uriel/',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://www.twitter.com/daveuriel/',
    },
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/daviduriel/',
    },

    {
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:david@daviduriel.com',
    },
  ],
};
